<template>
    <div>
        <nav
            class="mx-auto flex gap-x-4 max-w-7xl items-center justify-between p-6 lg:px-8"
            aria-label="Global">
            <div class="hidden lg:flex">
                <a
                    href="/"
                    class="-m-1.5 p-1.5">
                    <LogoImage class="h-5" />
                </a>
            </div>
            <div class="flex lg:hidden">
                <button
                    type="button"
                    class="-m-2.5 p-2.5 lg:hidden text-gray-400 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-200"
                    @click="layoutStore.isSidebarOpen = true">
                    <span class="sr-only">Open sidebar</span>
                    <Icon
                        name="heroicons:bars-3"
                        class="h-6 w-6"
                        aria-hidden="true" />
                </button>
            </div>
            <div class="hidden lg:flex lg:flex-1 lg:gap-x-12 align-center justify-center">
                <div
                    v-for="item in headerMenuFiltered"
                    :key="item.title">
                    <NuxtLink
                        v-if="item.path"
                        class="text-sm font-semibold leading-6 hover:text-primary focus:text-primary"
                        :class="currentPath(item.path) ? 'text-primary' : 'text-gray-900 dark:text-gray-100'"
                        :to="useLocalePath()(item.path)">
                        {{ $t(item.title) }}
                    </NuxtLink>

                    <a
                        v-else-if="item.function"
                        href="#"
                        class="text-sm font-semibold leading-6 text-gray-900 hover:text-primary focus:text-primary dark:text-gray-100"
                        @click.prevent="item.function">
                        {{ $t(item.title) }}
                    </a>
                </div>
            </div>
            <div
                v-if="authStore.isAuthenticated"
                class="flex gap-x-4 lg:gap-x-6 justify-end">
                <ServiceSwitcher />

                <button
                    type="button"
                    class="-m-2.5 p-2.5 text-gray-400 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-200 relative"
                    @click="inboxOpen = !inboxOpen">
                    <span class="sr-only">View notifications</span>
                    <Icon
                        name="heroicons:bell"
                        class="h-6 w-6"
                        aria-hidden="true" />
                    <span
                        v-if="notificationStore.unreadNotificationsCount > 0"
                        class="w-2 h-2 bg-danger-500 block absolute top-3 right-3 rounded-full" />
                    <NotificationInbox v-model="inboxOpen" />
                </button>

                <!-- Separator -->
                <div
                    class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                    aria-hidden="true" />

                <UserMenu />
            </div>
        </nav>
        
        <MobileMenuFrontend />
    </div>
</template>

<script setup>
import UserMenu from "@/components/layouts/UserMenu/UserMenu.vue";
import NotificationInbox from "@/components/global/NotificationInbox.vue";
import {useNotificationStore} from "@/store/notifications";
import {useLayoutStore} from "@/store/layout";
import {useAuthStore} from "@/store/auth";
import MobileMenuFrontend from "@/components/layouts/MobileMenuFrontend.vue";
import {useRoute} from "vue-router";

const inboxOpen = ref(false)

const notificationStore = useNotificationStore()
const layoutStore = useLayoutStore()
const authStore = useAuthStore()
const route = useRoute()

const headerMenuFiltered = computed(() => headerMenu.filter(item =>
    (authStore.isAuthenticated && item.showAuthenticated) || (!authStore.isAuthenticated && item.showUnauthenticated)))


const currentPath = (path) => {
    if (path === '/') {
        return route.path === path
    }
    return route.path === '/' + path
}

</script>