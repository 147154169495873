<template>
    <TransitionRoot
        as="template"
        :show="layoutStore.isSidebarOpen">
        <Dialog
            as="div"
            class="relative z-50 lg:hidden"
            @close="layoutStore.isSidebarOpen = false">
            <TransitionChild
                as="template"
                enter="transition-opacity ease-linear duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-900/80" />
            </TransitionChild>

            <div class="fixed inset-0 flex">
                <TransitionChild
                    as="template"
                    enter="transition ease-in-out duration-300 transform"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full">
                    <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                        <TransitionChild
                            as="template"
                            enter="ease-in-out duration-300"
                            enter-from="opacity-0"
                            enter-to="opacity-100"
                            leave="ease-in-out duration-300"
                            leave-from="opacity-100"
                            leave-to="opacity-0">
                            <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                                <button
                                    type="button"
                                    class="-m-2.5 p-2.5"
                                    @click="layoutStore.isSidebarOpen = false">
                                    <span class="sr-only">Close sidebar</span>

                                    <Icon
                                        name="heroicons:x-mark"
                                        class="h-6 w-6 text-white"
                                        aria-hidden="true" />
                                </button>
                            </div>
                        </TransitionChild>

                        <div
                            class="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-gray-900 px-6 pb-4 border-r border-gray-200 dark:border-gray-700">
                            <div class="flex h-16 shrink-0 items-center">
                                <LogoImage />
                            </div>
                            <nav class="flex flex-1 flex-col">
                                <ul
                                    role="list"
                                    class="flex flex-1 flex-col gap-y-7">
                                    <li
                                        v-for="item in headerMenu"
                                        v-show="item.ability ? $can(item.ability[0], item.ability[1]) : true"
                                        :key="item.title">
                                        <NuxtLink
                                            v-if="item.path"
                                            :class="[item.current ? 'bg-gray-50 text-primary-600' : 'text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']"
                                            :to="useLocalePath()(item.path)"
                                            @click="layoutStore.isSidebarOpen = false">
                                            {{ $t(item.title) }}
                                        </NuxtLink>
                                        <a
                                            v-else-if="item.function"
                                            href="#"
                                            :class="[item.current ? 'bg-gray-50 text-primary-600' : 'text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']"
                                            @click.prevent="item.function"
                                            @click="layoutStore.isSidebarOpen = false">
                                            {{ $t(item.title) }}
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script setup>
import {useLayoutStore} from "@/store/layout";

const layoutStore = useLayoutStore()
</script>